import React from 'react'
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import CrystalPurpleSvg from '../svg/crystal-purple.svg';
import PrefaceGreenCrystal from '../svg/preface-green-crystal.svg';
import PrefaceRedCrystal from '../svg/preface-red-crystal.svg';
import FloatAnimation from '../Atom/FloatAnimation';
import * as mediaQueries from '../../styles/js/mediaQueries';

const Container = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  background: var(--color-bg-offwhite);
  padding: 120px 0 160px;
  h4, h3 {
    width: 50%;
    margin: 0 auto;
    position: relative;
  }
  h3 {
    margin-top: 23px;
  }
  ${mediaQueries.largeAndBelow} {
    h4, h3 {
      width: 70%;
    }
  }
  ${mediaQueries.mediumAndBelow} {
    h4, h3 {
      width: 87%;
    }
  }
`

const H4 = styled.h4`
  color: var(--color-text-purple);
`

const StyledCrystal = styled(CrystalPurpleSvg)`
   animation: ${FloatAnimation} 4s ease-in-out infinite;
`

const StyledRedCrystal = styled(Parallax)`
  position: absolute;
  bottom: 20%;
  right: -199px;
  ${mediaQueries.largeAndBelow} {
    svg {
      width: 300px;
    }
  }
  ${mediaQueries.mediumAndBelow} {
    right: -100px;
    svg {
      width: 200px;
    }
  }
`

const StyledGreenCrystal = styled(Parallax)`
  position: absolute;
  top: 20%;
  left: -206px;
  ${mediaQueries.largeAndBelow} {
    svg {
      width: 300px;
    }
  }
  ${mediaQueries.mediumAndBelow} {
    left: -100px;
    svg {
      width: 200px;
    }
  }
`

class Preface extends React.Component {
  render() {
    return (
      <Container id="preface">
        <StyledRedCrystal
            offsetYMax="-20%"
            offsetYMin="20%"
            slowerScrollRate
        >
          <PrefaceRedCrystal />
        </StyledRedCrystal>
        <StyledGreenCrystal
            offsetYMax="20%"
            offsetYMin="-20%"
            slowerScrollRate
        >
          <PrefaceGreenCrystal />
        </StyledGreenCrystal>
        <StyledCrystal />
        <H4>SO BEGINS THE ADVENTURE</H4>
        <h3>A world within a world, Utomia is the city that defines this mystical planet. It is home to some of the brightest and most privileged Beings that exist. Surrounded by a magical Water Shield, the city is protected from dark forces who have plotted to bring its downfall for many millennia.<br />
        Our journey starts in a small farming village to the south of Utomia. Four best friends – two Lizards, a Meerkat, and a Kindly Bat – find themselves drawn, for better or worse, into the battle between good and evil.</h3>
      </Container>
    )
  }
}

export default Preface