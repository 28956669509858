import React from 'react'
import styled from 'styled-components';
import * as mediaQueries from '../../styles/js/mediaQueries';

const Container = styled.div`
  background: var(--color-bg-offwhite);
  display: flex;
  padding: 0 12.5%;
  margin-bottom: 180px;
  flex-direction: ${props => props.align === 'left' ? 'row' : 'row-reverse'};
  align-items: center;
  position: relative;
  ${mediaQueries.mediumAndBelow} {
    width: 87.5%;
    padding: 0 6.25%;
    margin-bottom: 175px;
  }
`
const ImageContainer = styled.div`
  width: 44%;
  img {
    width: 100%;
  }
  ${mediaQueries.mediumAndBelow} {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: -100px;
    img {
      width: auto;
      height: 100%;
    }
  }
`

const TextContainer = styled.div`
  width: 56%;
  text-align: left;
  z-index: 1;
  position: relative;
  h4 {
    margin-bottom: 18px;
    color: var(${props => props.color});
  }
  h2 {
    margin: 0 0 30px;
  }
  ${mediaQueries.mediumAndBelow} {
    width: 100%;
  }
`

class FiftyFifty extends React.Component {
  render() {
    const {
      img,
      eyebrow,
      header,
      body,
      alignment,
      color
    } = this.props
    return (
      <Container align={alignment}>
        <ImageContainer>
          <img src={img} alt={eyebrow} />
        </ImageContainer>
        <TextContainer color={color}>
          <h4>{eyebrow}</h4>
          <h2>{header}</h2>
          <p>{body}</p>
        </TextContainer>
      </Container>
    )
  }
}

export default FiftyFifty
