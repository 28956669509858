import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import Utomia from '../../images/utomia.png'
import CascadeSea from '../../images/cascade-sea.png'
import Forest from '../../images/forest.png'
import Plains from '../../images/plains.png'
import SouternHills from '../../images/southern-hills.png'
import CrystalGreenSvg from '../svg/crystal-green.svg'
import Arrow from '../svg/arrow.svg'
import FloatAnimation from '../Atom/FloatAnimation'
import * as mediaQueries from '../../styles/js/mediaQueries'

const Container = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  background: white;
  padding: 120px 0 160px;
  h4 {
    margin: 40px auto 0;
    position: relative;
  }
  h3 {
    margin-top: 23px;
  }
  .slick-list {
    padding: 0 20%;
    ${mediaQueries.largeAndBelow} {
      padding: 0 !important;
    }
  }
  .slick-slide {
    height: auto;
    img {
      margin: 0 auto;
      width: 100%;
    }
    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }
  .slick-dots {
    bottom: 0;
  }
`

const H4 = styled.h4`
  color: var(--color-text-green);
  text-transform: uppercase;
`

const StyledCrystal = styled(CrystalGreenSvg)`
  animation: ${FloatAnimation} 4s ease-in-out infinite;
`

const ItemContainer = styled.div`
  transform: scale(0.6);
  transition: transform 1.1s cubic-bezier(0.19, 1, 0.22, 1);
  .slick-current &,
  .slick-center & {
    transform: scale(0.9);
  }
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`

const ArrowLeft = styled.div`
  position: absolute;
  left: 15%;
  top: calc(50% - 11px);
  height: auto;
  width: auto;
  cursor: pointer;
  animation: ${FloatAnimation} 4s ease-in-out infinite;
  svg {
    .crystal-btn-stroke,
    .crystal-btn-bg {
      transition: stroke 0.3s ease-in, fill 0.3s ease-in;
    }
  }
  &:before {
    display: none;
  }
  &:hover {
    svg {
      .crystal-btn-stroke {
        stroke: var(--color-ui-dark-purple);
      }
      .crystal-btn-bg {
        fill: var(--color-ui-purple);
      }
    }
  }
  z-index: 20;
  ${mediaQueries.largeAndBelow} {
    display: none;
  }
`

const ArrowRight = styled.div`
  position: absolute;
  top: calc(50% - 11px);
  right: 15%;
  height: auto;
  width: auto;
  cursor: pointer;
  animation: ${FloatAnimation} 4s ease-in-out infinite;
  svg {
    transform: rotate(180deg);
    .crystal-btn-stroke,
    .crystal-btn-bg {
      transition: stroke 0.3s ease-in, fill 0.3s ease-in;
    }
  }
  &:before {
    display: none;
  }
  &:hover {
    svg {
      .crystal-btn-stroke {
        stroke: var(--color-ui-dark-purple);
      }
      .crystal-btn-bg {
        fill: var(--color-ui-purple);
      }
    }
  }
  z-index: 20;
  ${mediaQueries.largeAndBelow} {
    display: none;
  }
`

function NextArrow(props) {
  const { onClick } = props
  return (
    <ArrowRight onClick={onClick}>
      <Arrow />
    </ArrowRight>
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <ArrowLeft onClick={onClick}>
      <Arrow />
    </ArrowLeft>
  )
}

class ExploreWorld extends React.Component {
  render() {
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      centerPadding: '22%',
      speed: 1100,
      cssEase: 'cubic-bezier(.19,1,.22,1)',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '0',
          },
        },
      ],
    }
    return (
      <Container id="world">
        <StyledCrystal />
        <H4>Explore the world</H4>
        <Slider {...settings}>
          <ItemContainer>
            <h2>Utomia</h2>
            <img src={Utomia} alt="Utomia" />
            <p>
              Since its controversial beginnings, the great and mystical city of
              Utomia has been home to the most powerful society and military
              that exists. Its defining feature is a giant Water Clock that uses
              the mysterious energy of a large crystal, known as the Máthair, to
              slow down time within the confines of the city. Because of this
              most Beings live their entire lives either inside, or outside, the
              city’s vast Water Shield. Visitors in (or out) are rare.
            </p>
          </ItemContainer>
          <ItemContainer>
            <h2>Southern Hills</h2>
            <img src={SouternHills} alt="southern hills" />
            <p>
              The Southern Hills boasts a fertile and rich landscape known for
              its rolling green hills, strong biodiversity, and year-round
              growing season. The region has many peaceful agricultural villages
              ¾ including Talamh, where Laoch’s family and friends live ¾ and no
              cities of its own. Most communities in the Southern Hills have
              amicable, if distant, relations with Utomia and its policies.
            </p>
          </ItemContainer>
          <ItemContainer>
            <h2>Cascade Sea</h2>
            <img src={CascadeSea} alt="Cascade Sea" />
            <p>
              The Cascade Sea is not a sea at all, but rather a huge freshwater
              lake. The bottom is largely sandy, however some areas contain dead
              underwater forests. There are today around ten fishing villages
              dotting its shoreline, the largest of which is Tartaria, a small
              city well-known for hosting the Water Games, a year-round,
              highly-attended sporting competition.
            </p>
          </ItemContainer>
          <ItemContainer>
            <h2>Great Plains</h2>
            <img src={Plains} alt="plains" />
            <p>
              The Great Plains consists of vast, grassy terrain that surrounds
              Utomia on all sides. Distinctive features include the presence of
              phosphorescent mosses and sporadic areas of self-contained forest,
              some of which are rumored to be enchanted. The region is largely
              uninhabited, save a few small trading villages and the occasional
              lone nomad or band of Meerkat gypsies traversing its windswept
              expanse.
            </p>
          </ItemContainer>
          <ItemContainer>
            <h2>Middlands</h2>
            <img src={Forest} alt="Middlands" />
            <p>
              The Fasachlands is also a barren place. It has very little water
              and the most extreme temperatures of any of the biospheres. Cacti
              and certain types of spiky grass grow in this region, but
              otherwise it is devoid of plant life.
            </p>
          </ItemContainer>
        </Slider>
      </Container>
    )
  }
}

export default ExploreWorld
