import React from 'react'
import styled from 'styled-components'
import Book from '../../images/book.png'
import CrystalPurpleSvg from '../svg/crystal-purple.svg'
import CrystalButton from '../Atom/CrystalButton'
import FloatAnimation from '../Atom/FloatAnimation'
import * as mediaQueries from '../../styles/js/mediaQueries'

const Container = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  background: var(--color-bg-offwhite);
  padding: 120px 0 160px;
  h4 {
    width: 50%;
    margin: 0 auto;
    position: relative;
  }
  ${mediaQueries.largeAndBelow} {
    h4 {
      width: 70%;
    }
  }
  ${mediaQueries.mediumAndBelow} {
    h4 {
      width: 87%;
    }
  }
`
const FiftyFiftyWrap = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  ${mediaQueries.largeAndBelow} {
    flex-direction: column;
    justify-content: center;
  }
`

const LeftSide = styled.div`
  width: 54%;
  justify-content: center;
  img {
    width: 53%;
  }
  ${mediaQueries.largeAndBelow} {
    width: 100%;
  }
`

const RightSide = styled.div`
  width: 46%;
  text-align: left;
  p {
    max-width: 72%;
  }
  ${mediaQueries.largeAndBelow} {
    width: 87%;
    p {
      max-width: 100%;
    }
  }
`

const H4 = styled.h4`
  color: var(--color-text-purple);
  text-transform: uppercase;
`

const StyledCrystal = styled(CrystalPurpleSvg)`
  animation: ${FloatAnimation} 4s ease-in-out infinite;
`

class Buy extends React.Component {
  render() {
    return (
      <Container id="where-to-buy">
        <StyledCrystal />
        <H4>Book one</H4>
        <FiftyFiftyWrap>
          <LeftSide>
            <img src={Book} alt="Utomia - Buy Now" />
          </LeftSide>
          <RightSide>
            <h3>The Legend Beyond</h3>
            <p>
              A world within a world, Utomia is the city that defines this
              mystical planet. It is home to some of the brightest and most
              privileged Beings that exist. Surrounded by a magical Water
              Shield, the city is protected from dark forces who have plotted to
              bring its downfall for many millennia.
            </p>
            <CrystalButton
              to="https://www.amazon.com/Utomia-Legend-Beyond-Caitlin-Scholl/dp/1945293691"
              text="buy now"
              mobileHidden={false}
            />
          </RightSide>
        </FiftyFiftyWrap>
      </Container>
    )
  }
}

export default Buy
