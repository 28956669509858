import React from 'react'
import styled from 'styled-components'
import ATF from '../components/Organism/ATF'
import Preface from '../components/Molecule/Preface'
import ExploreWorld from '../components/Organism/ExploreWorld'
import Characters from '../components/Organism/Characters'
import Buy from '../components/Molecule/Buy'
import { ParallaxProvider } from 'react-scroll-parallax'
import Layout from '../components/layout'

const IndexPage = () => (
  <ParallaxProvider>
    <Layout>
      <ATF />
      <Preface />
      <ExploreWorld />
      <Characters />
      <Buy />
    </Layout>
  </ParallaxProvider>
)

export default IndexPage
