import React from 'react'
import styled from 'styled-components';
import LogoSvg from '../svg/Utomia-title.svg';
import BackgroundImage from '../../images/home-bg.png'
import * as mediaQueries from '../../styles/js/mediaQueries';

const Container = styled.div`
  height: 100vh;
  position: relative;
  background: var(--color-bg-offwhite) url(${BackgroundImage}) center center / cover no-repeat;
`

const H1 = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mediaQueries.mediumAndBelow} {
    width: 100%;
    svg {
      width: 70%;
      margin: 0 15%;
    }
  }
`

class ATF extends React.Component {
  render() {
    return (
      <Container>
        <H1>
          <LogoSvg alt="Utomia" />
        </H1>
      </Container>
    )
  }
}

export default ATF