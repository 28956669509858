import React from 'react'
import styled from 'styled-components'
import FiftyFifty from '../Molecule/FiftyFifty'
import CrystalRedSvg from '../svg/diamond-red.svg'
import FloatAnimation from '../Atom/FloatAnimation'

import Laoch from '../../images/laoch.png'
import Leeland from '../../images/leeland.png'
import Nudge from '../../images/nudge.png'
import Sty from '../../images/sty.png'

const Container = styled.div`
  background: var(--color-bg-offwhite);
  text-align: center;
  padding: 120px 0px;
  overflow: hidden;
  & > h4 {
    margin: 40px auto 0;
    position: relative;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`

const StyledCrystal = styled(CrystalRedSvg)`
  animation: ${FloatAnimation} 4s ease-in-out infinite;
`

const H4 = styled.h4`
  color: var(--color-text-red);
  text-transform: uppercase;
  margin-bottom: 120px !important;
`

const Characters = () => (
  <Container id="characters">
    <StyledCrystal />
    <H4>A Hero's Journey</H4>
    <FiftyFifty
      img={Laoch}
      eyebrow="GUANA/GECKO"
      header="Laoch (Lā-ok)"
      body="Laoch is an adolescent Lizard hybrid who dreams of a destiny beyond Talamh, his small farming village in the Southern Hills.  He spends his time tormenting his teacher, Ms. Whakdak, and conducting scientific experiments in a secret tree house built with his brother Leeland and best friends, Nudge and Sty. Laoch has a brilliant mind and is a natural born leader, however he has trouble admitting when he’s wrong."
      alignment="left"
      color="--color-text-purple"
    />
    <FiftyFifty
      img={Leeland}
      eyebrow="CHARMELEON"
      header="Leeland (Lē-lind)"
      body="Leeland is twin the brother of Laoch, and has always been the smaller and quieter of the two. Prone to daydreaming, he tends to observe life happen around him rather than propel it forward. He is respectful, soft spoken, and a hard worker. Leeland also possesses the “vise grip” (a common trait of those with Chameleon ancestry), and because he is artistically gifted has chosen to pursue a career as a Cobbler."
      alignment="right"
      color="--color-text-red"
    />
    <FiftyFifty
      img={Nudge}
      eyebrow="MEERKAT"
      header="Nudge (Nudj)"
      body="Nudge is one of Laoch and Leeland’s best childhood friends, and also grew up in Talamh. He is an energetic and happy-go-lucky Meerkat who has a great sense of humor (though he is also predisposed to giggling fits when he’s nervous, which can be awkward). He is a gifted athlete, and his family has hoped for some time that he would someday compete in the illustrious Water Games."
      alignment="left"
      color="--color-text-yellow"
    />
    <FiftyFifty
      img={Sty}
      eyebrow="BAT"
      header="Sty (Stī)"
      body="Sty is a Kindly Bat from Talamh, and the fourth best friend of Laoch, Leeland, and Nudge. Sty is quiet and gentle, and often retreats into his own thoughts. As an only child, his friendships with the others are especially important. Because Batkind is largely reviled throughout the world ¾ and because he is nocturnal ¾ Sty often feels different from his friends. Still, he cherishes their companionship."
      alignment="right"
      color="--color-text-blue"
    />
  </Container>
)

export default Characters
